<template>
      <a-sub-menu :key="menuInfo.key" v-bind="$props" v-on="$listeners">
        <span slot="title">
          <!-- <a-icon type="snippets" /> -->
          <a-icon type="book" />
          <span>{{ menuInfo.name }}</span>
        </span>
        <template v-for="item in menuInfo.children">
          <a-menu-item v-if="!item.children" :key="item.key" >
          <div style="display: flex; justify-content: space-between;">
            <div >
            <!-- <a-icon type="pie-chart" /> -->
            <!-- <a-icon type="google" /> -->
            <a-icon type="profile" />
            <span>{{ item.name }}</span>
            </div>
            <div>
              <a-icon v-if="isupdate" type="edit"   :title="$t('energy.enedata189')" @click="updateClick($event,item.key,item.name)"/>
              <a-icon v-if="isdelete" type="delete" :title="$t('energy.enedata169')" @click="deleteClick($event,item.key,item.name)"/>
            </div>
          </div>
          </a-menu-item>
          <sub-menu v-else :key="item.key" :menu-info="item" />
        </template>
      </a-sub-menu>
</template>
<script>
import { Menu } from 'ant-design-vue';
import SubMenu from "./eneMenu.vue";
export default {
  name: 'SubMenu',
  isSubMenu: true,
  props: {
    ...Menu.SubMenu.props,
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
    fatherMethod: {
        type: Function,
        default: null
    },
    fatherUpdateMethod:{
        type: Function,
        default: null
    },
    isdelete:{
      type:Boolean,
      default:true
    },
    isupdate:{
      type:Boolean,
      default:true
    }
  }, 
  methods: {
    deleteClick(e,no,text) {
      e.stopPropagation();
     if(this.fatherMethod)
      this.fatherMethod(e,no,text);
    },
    updateClick(e,no,text) {
       e.stopPropagation();
      if(this.fatherUpdateMethod)
          this.fatherUpdateMethod(e,no,text);
    }
    // titleClick(e) {
    //   //e.stopPropagation();
    //   console.log('titleClick', e);
    // },
  },
   components: {
    'sub-menu': SubMenu,
  },
}
</script>