<!--能源设置--趋势组组---->
<template>
  <div id="productionLine" v-loading="loading" :element-loading-text="$t('alarm.a0')"> 
    <!-- <my-header-title>生产单耗</my-header-title> -->
    <my-header-title> {{ $t("energy.enedata499") }} </my-header-title>
    <div style="display: flex;width:100%;height:100%;">
      <div class="side">
        <div class="mg">
          <div class="mg-add">
            <a-icon type="redo" style="vertical-align:10px;" @click="reflush" :title="$t('energy.enedata128')"/>
          </div> 
          <div class="mg-add">
            <a-icon type="plus-circle" style="vertical-align:10px;" @click="addClick" :title="$t('energy.enedata487')"/>
          </div> 
           <a-modal v-model="visible" :title="$t('energy.enedata483')" @ok="saveProductionLineName" :width='600'>
              <div v-loading="frmloading" :element-loading-text="$t('alarm.a0')">
                  <a-form-model ref="productionLineForm" 
                      :model="form" 
                      :rules="rules"
                      :label-col="formItemLayout.labelCol" 
                      :wrapper-col="formItemLayout.wrapperCol"  >
                          
                      <a-form-model-item  ref="name" prop="name">
                          <span slot="label">
                            {{ $t('energy.enedata484') }}&nbsp;
                            <a-tooltip :title='tooltip'>
                                <a-icon type="question-circle-o" />
                            </a-tooltip>
                          </span>
                          <a-input 
                              v-model="form.name"
                              @blur="
                              () => {
                                  $refs.name.onFieldBlur();
                              }
                              "
                              :placeholder="$t('energy.enedata480')"
                          />
                      </a-form-model-item>
                          
                  </a-form-model>
              </div>
            </a-modal>
        </div>

        <div class="side-menu">
          <a-menu
            mode="inline"
            :inline-collapsed="collapsed"
            :open-keys.sync="openKeys"
            @click="handleClick"
            v-model="menuKeys"
          >
            <template v-for="item in productionLineList">
              <a-menu-item v-if="!item.children" :key="item.key">
                <!-- <a-icon type="pie-chart" /> -->
                <a-icon type="profile" />
                <span>{{ item.name }}</span>
              </a-menu-item>
              <sub-menu v-else :key="item.key" :menu-info="item" :fatherMethod="deleteClick" :fatherUpdateMethod="updateClick"/>
            </template>
            
          </a-menu>
        </div>
        
      </div>

      <div class="main" ref="main" >
          <div class="header">
            <div class="header-left">
              
              
              <div class="header-condition">
                <span>{{$t('energy.enedata511')}}：</span>
                <a-range-picker
                  v-model="date"
                  :ranges="ranges"
                  :format="dateFormat"
                  @change="onChange"
                />
              </div>
            
            </div>
            <div>
              <a-button type="primary" style="width: 120px"  @click="search()">{{$t('energy.enedata006')}} </a-button>
            </div>
          </div>
          <div class="tb-main" ref="tb-main">
         <!--  -->
              <a-table 
              :row-selection="rowSelection"
              :columns="columns" 
              :data-source="data"
              :row-key="record => record.id"  
              :pagination="pagination"  
              :scroll="tscroll" 
              bordered size="middle" >
                      <template slot="chaozuo" slot-scope="text, record ">
                          <div class="editable-row-operations">
                              <a-button type="primary" size="small" @click="() => editRecord(record)" style="margin-right:5px;">{{$t('energy.enedata189')}}</a-button>
                              <a-popconfirm :title="$t('energy.enedata475')" @confirm="() => deleteRecord(record)">
                                  <a-button size="small" type="danger">{{$t('energy.enedata169')}}</a-button>
                              </a-popconfirm>
                          </div>
                      </template>
              </a-table>
            <div class="footer">
                <div>
                    <a-button type="primary" style="width: 120px" @click="batchDeleta" :disabled="selectedRowKeys.length==0">
                      {{$t('energy.enedata169')}}
                    </a-button>
                  
                </div>
                <div>
                   <a-button type="primary" style="width: 120px;margin-right:5px;" @click="csvimport">
                    {{$t('energy.enedata525')}}
                  </a-button>
                  <a-button type="primary" style="width: 120px;margin-right:5px;" @click="csvout">
                    {{$t('energy.enedata042')}}
                  </a-button>
                  <a-button type="primary" style="width: 120px" @click="addConsumption">
                      {{$t('energy.enedata191')}}
                  </a-button>
                </div>
            </div>
          </div>

          <pc-dialog :title="pcdTitle" :id="productionConsumptionId" :lineId="productionLineId" :lineList="lineList"></pc-dialog>
          <pc-upload-dialog :title="pcuploadTitle"></pc-upload-dialog>
          <!-- <a-empty>
            <span slot="description"> {{ $t("energy.setting.reportGr.empty") }} </span>
          </a-empty> -->
      </div>
    </div>
  </div>
</template>  
<script>
import { getProductionLineList,saveProductionLine,deleteProductionLine,getProductionConsumptionList,downloadEnergyCSVDataWithURL,deleteProductionConsumption  } from "../../../api/energy";
import SubMenu from "../components/measureGroup/eneMenu.vue";
import headerTitle from "../../../components/Title/headerTitle";
import ProductionConsumptionDialog from "./productionConsumptionDialog";
import ProductionConsumptionUploadDialog from "./productionConsumptionUploadDialog";
import { downloadUrl } from "../../../utils/utils";
import moment from 'moment';
const formItemLayout = {
  labelCol: {
            xs: { span: 24 },
            sm: { span: 10 },
        },
  wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
  },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

export default {
    name:'',
    data(){
     let cc = this.$t('energy.enedata544');
    let cm = this.$t('energy.enedata545');
    let lm = this.$t('energy.enedata546');
    
     let tooltip =this.$t("energy.setting.reportGr.stop")+' [ ` ~ ! @ # $ % ^ & *()_+<>?: " { } , . \ / ;  [ \ ] ] ·！#￥（——）：；“”‘、，|《。》？、【】';
      let normalName = (rule, value, callback) => {
          let regEn = /[`~!@#$%^&*+<>?:"{},\/;'[\]]/im;
          if(regEn.test(value)) {
              callback(new Error(tooltip));
          } else {
              callback();
          }
      };

      return {
        loading:false,
        collapsed:false,
        menuKeys:[],
        openKeys:["0@@ProductionLineType@@1"],
        productionLineList:[],
        rootLine:{
            key: "0@@ProductionLineType@@1",
            no: "0",
            name: this.$t('energy.enedata482'),
            children:[],
            level: "ProductionLineType",
            parentId: this.$route.query.id ,
            buildId: this.$store.getters.sitegrId ,
            rootId:  this.$store.getters.clientId,
            checkable: false,
            selectable: false,
          },
        addTitle:this.$t('energy.enedata483'),
        visible:false,
        frmloading:false,
        form:{
          id:0,name:undefined,
        },
        rules:{
          name: [
                { required: true, message: this.$t('energy.enedata480'), trigger: 'blur' },
                { min: 1, max: 30, message: this.$t('energy.enedata481'), trigger: 'blur' },
                { validator:normalName, trigger: 'blur' },
            ]
        },
        formItemLayout,
        tooltip:tooltip,

        productionLineId:0,
       
        // ranges:{ 
        //       '今天': [moment(), moment()]
        //     , '当月': [moment().startOf('month'), moment()]
        //     , '上月': [moment().add(-1,'month').startOf('month'),moment().add(-1,'month').endOf('month')] 
        // },
        ranges:{ 
              cc : [moment(), moment()]
            , cm : [moment().startOf('month'), moment()]
            , lm : [moment().add(-1,'month').startOf('month'),moment().add(-1,'month').endOf('month')] 
        },
        dateFormat: 'YYYY/MM/DD',
        date:[moment().startOf('month'), moment()],

        columns:[
          {title: 'NO'		 
            ,dataIndex: "NO",
              customRender: (text, record, index) =>
                `${
                  (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
                  index +
                  1
                }`,
              width: 60,fixed: "left"},
            {title: this.$t('energy.enedata484'), dataIndex: 'productionLineName',width:200      ,fixed: "left"},
            {title: this.$t('energy.enedata489'), dataIndex: 'productionName',width:200     ,fixed: "left" },
          
            {title: this.$t('consume.a15'), dataIndex: 'clientName',width:200      },
            {title: this.$t('energy.enedata254'), dataIndex: 'siteGrName',width:200      },
            {title: this.$t('consume.a14'), dataIndex: 'siteName',width:200      },
            {title: this.$t('energy.enedata490'), dataIndex: 'productionOrderNo',width:200      },
            {title: this.$t('energy.enedata491'), dataIndex: 'productionStartDate',width:150      },
            {title: this.$t('energy.enedata492'), dataIndex: 'productionEndDate',width:150      },
            {title: this.$t('energy.enedata493'), dataIndex: 'productionNumber',width:200      },
            {title: this.$t('energy.enedata494'), dataIndex: 'productionUnit2',width:80      },
            {title: this.$t('energy.enedata495'), dataIndex: 'productionConsumption',width:200      },
            {title: this.$t('energy.enedata496'), dataIndex: 'productionUnit1',width:80      },
            {title: this.$t('energy.enedata497'), dataIndex: 'productionUnitConsumption',width:200      },
            {title: this.$t('energy.enedata498'), dataIndex: 'productionUnit',width:80      },
            {title: this.$t('edge.edge015'), dataIndex: 'memo',width:200      },
            {
                title: this.$t('energy.enedata151'),
                dataIndex: "chaozuo",
                scopedSlots: { customRender: "chaozuo" },
                width: 200,
                fixed: "right",
            },
        ],
      
        pagination:{
            total: 0, //数据总数
            pageSize: 100, //每页中显示10条数据
            showTotal: (total) => {
              let msg = this.$t("energy.enedata195");
              msg = msg.replace('${total}',total);
              return msg;
                // `共 ${total} 条数据`
            }, //分页中显示总的数据
            showQuickJumper: true, //是否可以快速跳转至某页
            defaultCurrent: 1, //默认当前页面数
            hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
            onShowSizeChange: (current, pageSize) => {
              this.pagination.defaultCurrent = current;
              this.pagination.pageSize = pageSize;
              this.loadEquipmentList(1,current,pageSize);
            },
            // 改变每页数量时更新显示
            onChange: (current, size) => {
              this.pagination.defaultCurrent = current;
              this.pagination.pageSize = size;
              this.loadEquipmentList(1,current,size);
            },
        },

        data:[],
        selectedRowKeys:[],
        tscroll:{x:0,y:0},

        productionConsumptionVisible:false,
        productionConsumptionId:0,
        lineList:[],
        pcdTitle:this.$t('energy.enedata499'),
        idList:[],
        pcuploadTitle:this.$t('energy.enedata526'),//
        productionConsumptionUploadDialogVisible:false,
      }

    },
    activated() {
      this.loadProductionLineList();
    },
    mounted() { 
      this.tableSize();
      // 根据窗口变化计算表格高度
      window.addEventListener("resize", this.tableSize);
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.tableSize);
    },
    computed: {
      rowSelection() {
        const { selectedRowKeys } = this;
        return {
          selectedRowKeys,
          onChange: this.onSelectChange,
          hideDefaultSelections: true,
          type:'checkbox',
        };
      },
    },
    methods:{
      moment,
      onSelectChange(selectedRowKeys, selectedRows) {
        console.log("selectedRowKeys changed: ", selectedRowKeys);
        this.selectedRowKeys = selectedRowKeys;
        // this.selectedRows = selectedRows;
      },
      onChange(dates, dateStrings) {
        console.log('From: ', dates[0], ', to: ', dates[1]);
        console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
      },
      handleClick(e){
        console.log('handleClick', e);
        let vals = e.key.split("@@");
        console.log('update id', vals[0]);
        this.productionLineId = parseInt(vals[0]);
        this.search();
      },
      deleteClick(e,key,text) {
          console.log('deleteClick', e,key,text);
          let vals = key.split("@@");
          console.log('delete id', vals[0])
          this.$confirm({
              title: this.$t('energy.enedata486'),
              centered: true,
              onOk: () => {
                  this.deleteProductionLineInfo(vals[0]);
              }
          });
      },
      addClick(){
          if(this.$refs.productionLineForm){
            this.$refs.productionLineForm.resetFields();
          }
          this.form.name = undefined;
          this.form.id = 0;
          this.visible = true;
      },
      updateClick(e,key,text) {
        console.log('updateClick', this.form);
        if(this.$refs.productionLineForm){
          this.$refs.productionLineForm.resetFields();
        }
        console.log('updateClick', e,key,text);
        let vals = key.split("@@");
        console.log('update id', vals[0])
        this.form.name = text;
        this.form.id = vals[0];
        this.visible = true;
      },
      saveProductionLineName(){
           this.$refs.productionLineForm.validate(valid => {
             console.log('validate>>>',valid);
              if (valid) {
                  this.$confirm({
                      title: this.$t('energy.enedata485'),
                      centered: true,
                      onOk: () => {
                          this.saveProductionLineInfo();
                      }
                  });
              } else {
                  console.log('error submit!!');
                  return false;
              }
          });
      },
      deleteProductionLineInfo(id){
          this.loading = true;
          deleteProductionLine(id)
          .then((res) => {
              console.log("DeleteProductionLineInfo Result",res);
              if(res && (res.errorCode == '00' || res.errorCode == '05')){
                  this.$message.success(res.msg);
                  this.loadProductionLineList();
              }else{
                  this.$message.error(res.msg);
              }      
              this.loading = false;
          })
          .catch((err) => {
              this.loading = false;
              console.log(err);
          });
      },
      saveProductionLineInfo(){
          this.frmloading = true;
          let params = {
              action:1,
              clientId: this.$store.getters.clientId,
              sitegrId: this.$store.getters.sitegrId,
              siteId: this.$route.query.id,
              tenantId: 0,
              productionLineType: this.form.id,
              productionLineTypeName: this.form.name,
          }
          this.frmloading = true;
          console.log("SaveProductionLineInfo Result",params);
          saveProductionLine(params)
          .then((res) => {
              console.log("SaveProductionLineInfo Result",res);
              this.frmloading = false;

              if(res && (res.errorCode == '00' || res.errorCode == '03')){
                  this.$message.success(res.msg);
                  this.loadProductionLineList();
                  this.visible = false; 
              }else{
                  this.$message.error(res.msg);
              }      
          })
          .catch((err) => {
              this.frmloading = false;
              console.log(err);
          });
      },
      reflush(){
        this.productionLineId = 0;
        this.data = [];
        this.menuKeys=[];
        this.loadProductionLineList();
      },
      loadProductionLineList(){
        let params =  {
            action:1,
            clientId: this.$store.getters.clientId,
            sitegrId: this.$store.getters.sitegrId,
            siteId: this.$route.query.id,
            // sitegrId: 126,
            // siteId: 188,
            tenantId: 0,
        };
        console.log("LoadProductionLineList Params",params);
        this.loading = true;
        getProductionLineList(params)
        .then((res) => {
          console.log("LoadProductionLineList Result",res);

          if(res && res.errorCode == '00'){
              this.productionLineList = [];
              this.rootLine.children = res.data.productionLineTypeList;
              this.lineList = res.data.productionLineTypeList;
              this.productionLineList.push(this.rootLine);
          } else {
              this.$message.error(this.$t("energy.setting.reportGr.loadFail"));
          }     
          this.loading = false;
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
      },

      editRecord(record){
        this.productionConsumptionId = record.id;
        this.productionConsumptionVisible = true;
      },
      deleteRecord(record){
        this.idList = [];
        this.idList.push(record.id);
        this.deleteProductionConsumptionInfo(); 
      },
      batchDeleta(){
        let len = this.selectedRowKeys.length;
        if(len<1){
            this.$message.error(this.$t("energy.enedata520"));
            return ;
        }
        this.idList = this.selectedRowKeys;
        this.$confirm({
            title: this.$t('energy.enedata521'),
            centered: true,
            onOk: () => {
                this.deleteProductionConsumptionInfo();
            }
        });
      },
      addConsumption(){
        this.productionConsumptionId = 0;
        this.productionConsumptionVisible = true;
      },
      search(){
        this.selectedRowKeys=[];
        this.loadProductionConsumptionList();
      },
      loadProductionConsumptionList(){
        let params =  {
            action:1,
            clientId: this.$store.getters.clientId,
            sitegrId: this.$store.getters.sitegrId,
            siteId: this.$route.query.id,
            // sitegrId: 126,
            // siteId: 188,
            tenantId: 0,
            productionLineType:this.productionLineId,
            pageNum:this.pagination.defaultCurrent-1,
            pageSize:this.pagination.pageSize,
            productionStartDate:this.date[0].format('YYYY/MM/DD')+ ' 00:00:00',
            productionEndDate:this.date[1].format('YYYY/MM/DD')+ ' 23:59:59',
        };
        console.log("LoadProductionConsumptionList Params",params);
        this.loading = true;
        getProductionConsumptionList(params)
        .then((res) => {
          console.log("LoadProductionConsumptionList Result",res);

          if(res && (res.errorCode == '00' || res.errorCode == '01')){

              this.data = res.data.pageData.list;
              this.pagination.total = res.data.pageData.total;

          } else {

              this.$message.error(this.$t("energy.setting.reportGr.loadFail"));
          }     
          this.loading = false;
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
      },
      csvout(){
         let params =  {
            action:1,
            clientId: this.$store.getters.clientId,
            sitegrId: this.$store.getters.sitegrId,
            siteId: this.$route.query.id,
            // sitegrId: 126,
            // siteId: 188,
            tenantId: 0,
            productionLineType:this.productionLineId,
            pageNum:this.pagination.defaultCurrent-1,
            pageSize:this.pagination.pageSize,
            productionStartDate:this.date[0].format('YYYY/MM/DD')+ ' 00:00:00',
            productionEndDate:this.date[1].format('YYYY/MM/DD')+ ' 23:59:59',
        };

        
        this.loading = true;

        downloadEnergyCSVDataWithURL('bivale/energy/production/consumption/download',params)
        .then((res) => {
            let name = (this.$store.getters.siteServiceTypeName?this.$store.getters.siteServiceTypeName+'-':'')+this.$t('energy.enedata499')+'-'+moment().format('YYYYMMDDHHmmssSSS')+'.zip';
            console.log("downloadEnergyCSVDataWithURL Result",name);
            downloadUrl(res,name);
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        })
        .finally(()=>{
            this.loading = false;
        });
      },
      deleteProductionConsumptionInfo(){
        let params =  {
            action:1,
            clientId: this.$store.getters.clientId,
            sitegrId: this.$store.getters.sitegrId,
            siteId: this.$route.query.id,
            tenantId: 0,
            idList:this.idList,
        };
        console.log("DeleteProductionConsumptionInfo Params",params);
        this.loading = true;
        deleteProductionConsumption(params)
        .then((res) => {
          console.log("DeleteProductionConsumptionInfo Result",res);
          if(res && (res.errorCode == '00' || res.errorCode == '05')){
             this.$message.success(res.msg);
             this.search();

          } else {

              this.$message.error(res.msg);
          }     
          this.loading = false;
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
      },
      // 表格高度计算
      tableSize() {
          setTimeout(() => {
              let height = this.$refs.main.clientHeight;
              let width = this.$refs.main.clientWidth;
              let tableHeight = height - 200;
              this.tscroll.y = tableHeight;
              this.tscroll.x = width;
          }, 0);
      },
      csvimport(){
        this.productionConsumptionUploadDialogVisible = true;
      },
      
    },
    components: {
      'sub-menu': SubMenu,
      'my-header-title':headerTitle,
      'pc-dialog':ProductionConsumptionDialog,
      'pc-upload-dialog':ProductionConsumptionUploadDialog,
    },
}
</script>      
   
<style scoped>
  #productionLine {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
  }
  ul, li {
    list-style: none;
    margin: 0;
  }
  .header-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 18px;
  }
  .header-title::before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 22px;
    background: #7585cd;
    margin-right: 20px;
  }
  .side {
    width: 280px;
    height: calc(100% - 60px);
    box-shadow: 0px 3px 8px #e7e6e6;
    position: relative;
  }
  .side-menu{
    max-height:  calc(100% - 50px);
    overflow: auto;
  }
  .main {
    width: calc(100% - 280px);
    height: calc(100% - 40px);
    padding: 5px;
  }
  .mg{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 10px;
  }
  .mg-add{
    width: 24px;
    height: 24px;
    border-radius: 24px;
    font-size: 22px;
    color: #7682ce;
    border:1px solid #FFFFFF;
    margin: 3px;
  }
  .mg-add:hover{
    color: #FFFFFF;
    background-color: #7682ce;
    border:1px solid #7682ce;
  
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    overflow: hidden;
  }
  .header-left {
    display: flex;
  }
  .header-condition {
    display: flex;
    align-items: center;
    font-size: 14px;
    white-space: nowrap;
    margin-right: 25px;
  }

  .tb-main {
    width: 100%;
    height: calc(100% - 40px);
  }
  .btn-display{
    display: flex; justify-content: flex-end;
  }
  .button{
    padding: 10px 0;
  }
  .footer {
    display: flex;
    justify-content:space-between;
    padding: 0 5px;
  }
</style>