<template>
  <div id="productionConsumptionUploadDialog" ref="productionConsumptionUploadDialog" style=" position: relative;">
      <a-modal  v-model="visible"
              :title="title" 
              :okText="okText"
              :ok-button-props="okButtonProps"
              @ok="edgeModalHandleOk"
              @cancel="edgeModalHandleCancel"
              :getContainer="() => this.$refs.productionConsumptionUploadDialog"
      >

          <div v-loading="uploading" :element-loading-text="uploadingTxt">
              
              <a-row>
                <a-col :span="24"  style="padding:10px;">
                 <div class="clearfix">
                    <a-upload :file-list="fileList" :multiple="false" :remove="handleRemove" :before-upload="beforeUpload">
                      <a-button> <a-icon type="upload" /> {{$t('edge.edge056')}} </a-button>
                    </a-upload>
                    <!-- <a-button
                      type="primary"
                      :disabled="fileList.length === 0"
                      :loading="uploading"
                      style="margin-top: 16px"
                      @click="handleUpload"
                    >
                      {{ uploading ? 'Uploading' : 'Start Upload' }}
                    </a-button> -->
                  </div>
                </a-col>
              </a-row>
           
            
              <div  style="position: absolute;bottom: 0;left: 0;padding:10px;display: flex; justify-content: space-between;">
                  <a-button type="primary" @click="downloadTemplateFile" :title="$t('energy.enedata529')" > <a-icon type="download" />{{$t('energy.enedata527')}}</a-button>
                  <a-button type="danger" @click="downloadErrResult" :title="$t('energy.enedata531')" v-if="errlist.length>0" style="margin-left: 5px;"> <a-icon type="download"/> {{$t('energy.enedata530')}} </a-button>
              </div>
          </div>
      </a-modal>
  </div>
</template>

<script>


import {uploadProductionConsumption} from "../../../api/energy";
export default {
  name: "productionConsumptionUploadDialog",  
  props: {
    title: {
      type: String,
      default: '导入生产能耗数据',
    }
  },
  data() {
    return {

      loading : false,
      visible : false,
      upurl:"",
      headers: {
        authorization: 'authorization-text',
      },

      fileList:[],
      uploading:false,
      okText: this.$t('edge.edge058'),
      uploadingTxt:this.$t('edge.edge057'),
      okButtonProps:{ props: { disabled: true }, },
      errlist:[],
      fileName:'',
      header: this.$t('energy.enedata532'),//"NO,生产线名称,产品名称,生产订单号,生产开始日期,生产结束日期,生产数量,产品单位,生产能耗,能耗单位,备注",
    };
  }, 
  methods: {
    downloadTemplateFile(){
      let fileName = this.$t('energy.enedata528');//"生产能耗模板文件";
      this.download(this.header,fileName);
    },
    downloadErrResult(){
        let result = '';
        this.errlist.forEach(element => {
          result+=element+"\r\n";
        });
        console.log(this.fileName);
        let fileName = 'err'
        console.log('result',result);
        this.download(result,fileName);
        
      },
     download(result,fileName){
// 判断浏览器类型
        if ((navigator.userAgent.indexOf('compatible') > -1 &&
            navigator.userAgent.indexOf('MSIE') > -1) ||
            navigator.userAgent.indexOf('Edge') > -1) {
            //IE10或Edge浏览器
            const BOM = "\uFEFF";
            const csvData = new Blob([BOM+result], { type: "text/csv" });
            navigator.msSaveBlob(csvData, `${fileName}.csv`);
        } else {
            //非IE浏览器
            const csvContent = "data:text/csv;charset=utf-8,\uFEFF" + result;
            //使用a标签的download属性实现下载功能
            const link = document.createElement("a");
            link.href = encodeURI(csvContent);
            link.download = `${fileName}.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
     },
     edgeModalHandleOk(){
       this.handleUpload();
     },
     edgeModalHandleCancel(){},
     handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      this.okButtonProps.props.disabled =  (this.fileList.length ===0);
      this.errlist = [];
    },
    beforeUpload(file) {
      let names = file.name.split(".");
      let len = names.length;
      if(names[len-1]!='csv'){
         this.$message.error(this.$t('energy.enedata543'));
         //  this.$message.error('文件格式错误，请选择CSV文件。');
         return false;
      }
      this.errlist = [];
      this.fileList = [file];
      this.okButtonProps.props.disabled =  (this.fileList.length ===0);
      return false;
    },
    handleUpload() {
      const { fileList } = this;
      const formData = new FormData();
      fileList.forEach(file => {
        formData.append('file', file);
      });
      
      let param =  {
          action:1,
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: this.$route.query.id,
          tenantId: 0
      };
      formData.append('param', JSON.stringify(param));
      formData.append('siteId', this.$route.query.id);
      this.uploading = true;
      this.okText = this.uploadingTxt;
      // You can use any AJAX library you like
      uploadProductionConsumption(formData)
      .then((res)=>{
          console.log('UploadFile>>',res);
          if((res.errorCode=='00' || res.errorCode=='03') && res.data){
            if(res.data.errVales){
              this.errlist = res.data.errVales;
            }else{
              this.errlist = [];
            }

            if(this.errlist.length>0){
                this.$message.error(this.$t('edge.edge060'));
                this.fileName = this.fileList[0].fileName;
            }else{
                this.$message.success(this.$t('edge.edge059'));
                this.visible = false;
                this.$parent.loadProductionLineList();
                this.$parent.search();
            }
            this.fileList = [];
            
          }else{
            this.$message.error(res.msg);
          }
          this.okText = this.$t('edge.edge058');
          this.uploading = false;
      }).catch((err)=>{
          this.uploading = false;
          this.okText = this.$t('edge.edge058');
          this.$message.error(this.$t('edge.edge060'));
      });
      
    }, 
    
  },
  
  watch:{
    "$parent.productionConsumptionUploadDialogVisible":{
      handler(val){
          if(val){              
                // this.resetForm();
                this.fileList =[];
                this.uploading = false;
                this.visible = val;
                this.errlist=[];
          }
      },
      deep:true,
      immediate:true,
    },
    "visible":{
      handler(val){
          if(!val){
              this.$parent.productionConsumptionUploadDialogVisible =  this.visible;
          }
      },
      deep:true,
      immediate:true,
    },
  },

  components: {
  },
};
</script>
<style scoped>

</style>