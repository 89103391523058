<template>
  <div id="productionConsumptionDialog" ref="productionConsumptionDialog">
    <a-modal v-model="visible" :title="title" :width="1000" :getContainer="() => this.$refs.productionConsumptionDialog" :footer="null">
        <div v-loading="cloading" :element-loading-text="$t('alarm.a0')">
            <a-form-model ref="ruleForm" 
            :model="form" 
            :rules="rules"
            :label-col="labelCol" 
            :wrapper-col="wrapperCol"  >
            <a-row :gutter="24">
                <a-col :span="12">
                    <!-- 1.生产线 -->
                    <a-form-model-item :label="$t('energy.enedata482')" ref="productionLine" prop="productionLine">
                        <a-select v-model="form.productionLine" :placeholder="$t('energy.enedata500')" @change="handleChange">
                            <a-select-option
                                v-for="(itme, index) in lineList"
                                :key="index"
                                :value="itme.no"
                                >
                                {{ itme.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="12">
                     <!-- 10.生产周期 -->
                    <a-form-model-item :label="$t('energy.enedata511')" ref="productionDateRange" prop="productionDateRange">
                        <a-range-picker
                        v-model="form.productionDateRange"
                        :ranges="ranges"
                        :format="dateFormat"
                        @change="onChange"
                        />
                    </a-form-model-item>
                </a-col>
            </a-row>

            <a-row :gutter="24">
                <a-col :span="12">
                    <!-- 2.产品名称 :label="$t('energy.enedata489')"-->
                    <a-form-model-item  ref="productionName" prop="productionName">
                        <span slot="label">
                                        {{$t('energy.enedata489')}}&nbsp;
                            <a-tooltip :title='disallowed'>
                            <a-icon type="question-circle-o" />
                            </a-tooltip>
                        </span>
                        <a-input 
                            v-model="form.productionName"
                            @blur="
                            () => {
                                $refs.productionName.onFieldBlur();
                            }
                            "
                            :placeholder="$t('energy.enedata501')"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="12">
                    <!-- 3.生产订单号 :label="$t('energy.enedata490')"-->
                    <a-form-model-item  ref="productionOrderNo" prop="productionOrderNo">
                        <span slot="label">
                                        {{$t('energy.enedata490')}}&nbsp;
                            <a-tooltip :title='disallowed'>
                            <a-icon type="question-circle-o" />
                            </a-tooltip>
                        </span>
                        <a-input 
                            v-model="form.productionOrderNo"
                            @blur="
                            () => {
                                $refs.productionOrderNo.onFieldBlur();
                            }
                            "
                            :placeholder="$t('energy.enedata502')"
                        />
                    </a-form-model-item>
                </a-col>
            </a-row>

            <a-row :gutter="24">
                <a-col :span="12">
                    <!-- 4.生产数量 -->
                    <a-form-model-item :label="$t('energy.enedata493')" ref="productionNumber" prop="productionNumber">
                        
                        <a-input-number style="width:100%;"
                            v-model="form.productionNumber"
                            @blur="
                            () => {
                                $refs.productionNumber.onFieldBlur();
                                if(form.productionConsumption && form.productionNumber){
                                    form.productionUnitConsumption = form.productionConsumption / form.productionNumber;
                                }
                            }
                            "
                            :placeholder="$t('energy.enedata505')"
                        />
                    </a-form-model-item>
                

                </a-col>
                <a-col :span="12">
                    <!-- 5.计量单位 :label="$t('energy.enedata494')"-->
                    <a-form-model-item  ref="productionUnit2" prop="productionUnit2">
                        <span slot="label">
                                        {{$t('energy.enedata494')}}&nbsp;
                            <a-tooltip :title='disallowed'>
                            <a-icon type="question-circle-o" />
                            </a-tooltip>
                        </span>
                        <a-input 
                            v-model="form.productionUnit2"
                            @blur="
                            () => {
                                $refs.productionUnit2.onFieldBlur();
                                if(form.productionUnit2 && form.productionUnit1){
                                    form.productionUnit = form.productionUnit1 + '/' + form.productionUnit2;
                                }
                            }
                            "
                            :placeholder="$t('energy.enedata506')"
                        />
                    </a-form-model-item>
                </a-col>
            </a-row>

             <a-row :gutter="24">
                <a-col :span="12">
                    <!-- 6.生产能耗 -->
                    <a-form-model-item :label="$t('energy.enedata495')" ref="productionConsumption" prop="productionConsumption">
                        
                        <a-input-number  style="width:100%;"
                            v-model="form.productionConsumption"
                            @blur="
                            () => {
                                $refs.productionConsumption.onFieldBlur();
                                if(form.productionConsumption && form.productionNumber){
                                    form.productionUnitConsumption = form.productionConsumption / form.productionNumber;
                                }
                            }
                            "
                            :placeholder="$t('energy.enedata509')"
                        />
                    </a-form-model-item>
                

                </a-col>
                <a-col :span="12">
                    <!-- 7.能耗单位 :label="$t('energy.enedata496')"-->
                    <a-form-model-item  ref="productionUnit1" prop="productionUnit1">
                        <span slot="label">
                                        {{$t('energy.enedata496')}}&nbsp;
                            <a-tooltip :title='disallowed'>
                            <a-icon type="question-circle-o" />
                            </a-tooltip>
                        </span>
                        <a-input 
                            v-model="form.productionUnit1"
                            @blur="
                            () => {
                                $refs.productionUnit1.onFieldBlur();
                                if(form.productionUnit2 && form.productionUnit1){
                                    form.productionUnit = form.productionUnit1 + '/' + form.productionUnit2;
                                }
                            }
                            "
                            :placeholder="$t('energy.enedata510')"
                        />
                    </a-form-model-item>
                </a-col>
            </a-row>

            <a-row :gutter="24">
                <a-col :span="12">
                     <!-- 8.生产单耗 -->
                <a-form-model-item :label="$t('energy.enedata497')" >
                    <a-input  v-model="form.productionUnitConsumption" :read-only="true" />
                </a-form-model-item>
               

                </a-col>
                <a-col :span="12">
                     <!-- 9.单耗单位 -->
                    <a-form-model-item :label="$t('energy.enedata498')" >
                        <a-input  v-model="form.productionUnit" :read-only="true"  />
                    </a-form-model-item>

                </a-col>
            </a-row>

             <a-row :gutter="24">
                <a-col :span="12">
                     <!-- 11.备注说明 -->
                    <a-form-model-item ref="memo" prop="memo">
                        <span slot="label">
                                        {{$t('edge.edge015')}}&nbsp;
                            <a-tooltip :title='disallowed'>
                            <a-icon type="question-circle-o" />
                            </a-tooltip>
                        </span>
                        <a-input 
                            v-model="form.memo"
                            @blur="
                            () => {
                                $refs.memo.onFieldBlur();
                            }
                            "
                            type="textarea" 
                        />
                    </a-form-model-item>

                </a-col>
               
            </a-row>

             <a-row :gutter="24" v-if="this.id>0">
                <a-col :span="12">
                     <!-- 创建日期 -->
                <a-form-model-item :label="$t('information.a241')" >
                    <a-input  v-model="form.createDate" :read-only="true" />
                </a-form-model-item>
               

                </a-col>
                <a-col :span="12">
                     <!-- 更新日期 -->
                    <a-form-model-item :label="$t('information.a242')" >
                        <a-input  v-model="form.lastUpdate" :read-only="true"  />
                    </a-form-model-item>

                </a-col>
            </a-row>

            <!-- <a-row :gutter="24">
                <a-col :span="12">
                    

                </a-col>
                <a-col :span="12">
                    
                </a-col>
            </a-row> -->
                
                
            <a-form-model-item v-bind="tailFormItemLayout">
                <a-button type="primary" @click="onSubmit">
                    {{$t('energy.enedata173')}}
                </a-button>
                <a-button style="margin-left: 10px;" @click="resetForm" v-if="id==0">
                    {{$t('energy.enedata333')}}
                </a-button>
            </a-form-model-item>
                
            </a-form-model>
        </div>
    </a-modal>
  </div>
</template>
<script>
import {saveProductionConsumption, getProductionConsumption} from "../../../api/energy";
import moment from 'moment';
export default {
  name: 'ProductionConsumptionDialog',
  props: {
    title: {
      type: String,
      default: '生产单耗设置',
    },
    id: {
      type: Number,
      default:0,
    },
    lineId: {
      type: Number,
      default:0,
    },
    lineList: {
      type: Array,
      default:[{no:'',name:''}],
    }

  },
  data() {
    let normalName = (rule, value, callback) => {
        let regEn = /[`~!@#$%^&*+<>?:",;']/im;

        if(regEn.test(value)) {
            callback(new Error(this.$t('energy.enedata218')));
        } else {
            callback();
        }
    };

    let numberVail = (rule, value, callback) => {
        if(value==0) {
            callback(new Error(this.$t('energy.enedata524')));
        } else {
            callback();
        }
    };
    return {
        cloading:false,        
        visible:false,
        
        form:{
            productionLine:'',
            productionName:'',
            productionOrderNo:'',
            productionStartDate:'',
            productionEndDate:'',
            productionNumber:1,
            productionUnit2:'',
            productionConsumption:0,
            productionUnit1:'',
            productionUnitConsumption:0,
            productionUnit:'',
            memo:'',
            id:0,
            productionDateRange:[moment(), moment()],
            createDate:'',
            lastUpdate:'',
        },
        labelCol: {
            xs: { span: 24 },
            sm: { span: 10 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },

        tailFormItemLayout: {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 12,
                    offset: 12,
                },
            },
        },
        
        rules: {
            productionNumber: [
                { required: true, message: this.$t('energy.enedata505'), trigger: 'blur' },
                { validator:numberVail, trigger: 'blur' },
            ],
            productionConsumption: [
                { required: true, message: this.$t('energy.enedata507'), trigger: 'blur' },
                { validator:normalName, trigger: 'blur' },
            ],
            productionOrderNo: [
                { min: 1, max: 30, message: this.$t('energy.enedata514'), trigger: 'blur' },
                { validator:normalName, trigger: 'blur' },
            ],
            productionName: [
                { min: 1, max: 30, message: this.$t('energy.enedata513'), trigger: 'blur' },
                { validator:normalName, trigger: 'blur' },
            ],
            memo: [
                { min: 1, max: 300, message: this.$t('energy.enedata517'), trigger: 'blur' },
                { validator:normalName, trigger: 'blur' },
            ],
            productionUnit1: [
                { required: true, message: this.$t('energy.enedata508'), trigger: 'blur' },
                { min: 1, max: 30, message: this.$t('energy.enedata516'), trigger: 'blur' },
                { validator:normalName, trigger: 'blur' },
            ],
            productionUnit2: [
                { required: true, message: this.$t('energy.enedata506'), trigger: 'blur' },
                { min: 1, max: 30, message: this.$t('energy.enedata515'), trigger: 'blur' },
                { validator:normalName, trigger: 'blur' },
            ],
            productionLine: [{ required: true, message: this.$t('energy.enedata500'), trigger: 'change' }],
            productionDateRange:[{
                type: 'array',
                required: true,
                message: this.$t('energy.enedata512'),//'请选择所属',
                trigger: 'change',
                 },
            ],
        }, 
        disallowed:this.$t('energy.setting.reportGr.stop')+'[`~!@#$%^ &amp; *()_+<>?: " { } , . \ / ;  [ \ ] ] ·！#￥（——）：；“”‘、，|《。》？、【】',
        ranges:{ 
              '今天': [moment(), moment()]
            , '当月': [moment().startOf('month'), moment()]
            , '上月': [moment().add(-1,'month').startOf('month'),moment().add(-1,'month').endOf('month')] 
        },
        dateFormat: 'YYYY/MM/DD',
        
    };
  },
  mounted(){
   
  },
  watch:{
    "$parent.productionConsumptionVisible":{
      handler(val){
          console.log('watch productionConsumptionVisible>>>',val)
          if(val){              
              this.visible = this.$parent.productionConsumptionVisible;
              this.resetForm();
              if(this.id>0){
                  this.loadInfo();
              }else{
                  this.form = {
                        productionLine:this.lineId===0?'':this.lineId,
                        productionName:'',
                        productionOrderNo:'',
                        productionStartDate:'',
                        productionEndDate:'',
                        productionNumber:1,
                        productionUnit2:'',
                        productionConsumption:0,
                        productionUnit1:'',
                        productionUnitConsumption:0,
                        productionUnit:'',
                        memo:'',
                        id:0,
                        productionDateRange:[moment(), moment()],
                        createDate:'',
                        lastUpdate:'',
                    };
                    this.cloading = false;
              }
              console.log('watch productionConsumptionVisible form>>>',this.form)
          }
      },
      deep:true,
      immediate:true,
    },
    "visible":{
      handler(val){
          console.log('watch visible>>>',val)
          if(!val){
              this.$parent.productionConsumptionVisible =  val;
              this.resetForm();
              console.log('watch visible  this.$parent.productionConsumptionVisible>>>', this.$parent.productionConsumptionVisible);
              this.cloading = false;
          }
      },
      deep:true,
      immediate:true,
    },
    
  },
  methods: {
        onChange(dates, dateStrings) {
            console.log('From: ', dates[0], ', to: ', dates[1]);
            console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
            this.form.productionStartDate = dateStrings[0] + ' 00:00:00';
            this.form.productionEndDate   = dateStrings[1] + ' 23:59:59';
        },
        handleChange(e){
            
        },
        loadInfo(){
            this.cloading = false;
            getProductionConsumption(this.id)
            .then((res) => {
                console.log("GetProductionConsumption Result",res.data);
                if(res.errorCode === '00'){
                    if(res.data){
                        // this.form.id = res.data.id;
                        // this.form.equiv = res.data.equivDay;
                        // this.form.unit = res.data.unit;
                        // this.form.year = res.data.year;
                        // this.form.kind = res.data.equivKind+"";
                        // this.form.areaId = res.data.areaId;
                        let com = res.data.consumption;
                        let star = !com.productionStartDate || com.productionStartDate==='' ?undefined : new moment(com.productionStartDate);
                        let end = !com.productionEndDate || com.productionEndDate==='' ?undefined : new moment(com.productionEndDate);
                        let range =[];
                        if(star)
                            range.push(star);
                        if(end)
                            range.push(end);
                        this.form = {
                            productionLine:parseInt(com.productionLineType),
                            productionName:com.productionName,
                            productionOrderNo:com.productionOrderNo,
                            productionStartDate:com.productionStartDate+" 00:00:00",
                            productionEndDate:com.productionEndDate+" 23:59:59",
                            productionNumber:com.productionNumber,
                            productionUnit2:com.productionUnit2,
                            productionConsumption:com.productionConsumption,
                            productionUnit1:com.productionUnit1,
                            productionUnitConsumption:com.productionUnitConsumption,
                            productionUnit:com.productionUnit,
                            memo:com.memo,
                            id:com.id,
                            productionDateRange:range,
                            createDate:com.createDate,
                            lastUpdate:com.lastUpdate,
                        };

                        console.log("GetProductionConsumption Result",this.form);
                    }
                }
                this.cloading = false;
            })
            .catch((err) => {
                this.cloading = false;
                console.log('GetProductionConsumption',err);
            });
        },
        onSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                     this.$confirm({
                        title: this.$t('energy.enedata518'),//'确认要保存所设置的虚拟点信息吗？',
                        centered: true,
                        onOk: () => {
                            this.savaProductionConsumptionValue();
                        },
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm() {
            if(this.$refs.ruleForm){
                this.$refs.ruleForm.resetFields();
            }
        },
        savaProductionConsumptionValue(){
            
            let consumption = this.form;
            if(!consumption.productionStartDate || consumption.productionStartDate=='')
                consumption.productionStartDate = this.form.productionDateRange[0].format('YYYY/MM/DD') + " 00:00:00";
            if(!consumption.productionEndDate || consumption.productionEndDate=='')
                consumption.productionEndDate  = this.form.productionDateRange[1].format('YYYY/MM/DD') + " 23:59:59";
            consumption.productionLineType  = consumption.productionLine;
            
            let param =  {
                    action:1,
                    clientId: this.$store.getters.clientId,
                    sitegrId: this.$store.getters.sitegrId,
                    siteId: this.$route.query.id,
                    tenantId: 0,
                    consumption:consumption,
                };
            this.cloading = true;
            console.log("SavaProductionConsumptionValue param",param);
            saveProductionConsumption(param)
            .then((res) => {
                console.log("SavaProductionConsumptionValue Result",res);
                console.log("SavaProductionConsumptionValue ErrorCode",res.errorCode);
                if(res.errorCode == '00' || res.errorCode == '03' ){
                   this.$message.success(res.msg);
                   this.visible = false;
                   this.$parent.search();
                } else {
                    this.$message.error(res.msg);
                }
                this.cloading = false;
            })
            .catch((err) => {
                this.cloading = false;
                console.log('SavaProductionConsumptionValue',err);
            });
        },
  },
};
</script>
<style scoped>
    #productionConsumptionDialog{
        height: 100%;
        width:100%;
        position: relative;
    }
    div /deep/.ant-modal-body{padding:10px;}
</style>
